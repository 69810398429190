
// Libraries
import * as React from 'react'

// Components
import Layout from '../../../components/layout'
import Seo from '../../../components/seo'
import DuoSection from '../../../components/duoSection'
import Button from '../../../components/button'
import InputFactory from '../../../components/inputFactory'
import { Link, navigate }  from 'gatsby'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowLeft} from '@fortawesome/pro-regular-svg-icons'

class OrganisationNotFoundPage extends React.Component {
	state = {
		buttonText: <>Copy<span className="d-md-none"> Link</span></>
	}

	render() {
		const {buttonText} = this.state

		return <Layout className="page--organisation-not-found nav-blue-half no-footer">
			<Seo title="Organisation Not Found" />
			<DuoSection organisationNotFound>
				<div>
					<div className="content-wrap">
						<p><Link to="#" onClick={() => navigate(-1)} className="link--back line--text"><span><FontAwesomeIcon icon={faArrowLeft} /> Back</span></Link></p>
						<h1>Your organisation is not part of our system</h1>
					</div>
				</div>
				<div>
					<div className="content-wrap">
						<p>You can talk to a superior and ask them to register at this link:</p>
						<form>
							<InputFactory
								type="text"
								name="invite_url"
								value="https://www.concertsforcarers.org.uk/register/organisation/"
							/>
							<Button type="button" onClick={this.handleCopyLink} hollow colorWhite>{buttonText}</Button>
						</form>
					</div>
				</div>
			</DuoSection>
		</Layout>
	}

	handleCopyLink = () => {
		const locationHost = typeof window !== 'undefined' ? window.location.protocol + '//' + window.location.host : ''
		if (locationHost) {
			navigator.clipboard.writeText(`${locationHost}/register/organisation/`)
			this.setState({
				buttonText: <>Copied<span className="d-md-none"> Link</span></>
			})
		}
	}
}

export default OrganisationNotFoundPage
